import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { ApplicationService } from 'src/app/services/application.service';
import { SeoService } from 'src/app/services/seo.service';
import { loadUserById, verifyAccountAttempt } from 'src/app/store/auth/auth.actions';
import { selectHasAcceptedOurConditionsAndPrivacyPolicy, selectIsAuth, selectUser } from "src/app/store/auth/auth.selectors";
import { AppCreationOrder } from 'src/app/store/order/models/creation-order.model';
import { clearCreationOrder, setCreationOrder, setTemplateType } from 'src/app/store/order/order.actions';
import { selectTemplates } from 'src/app/store/repository/repository.selector';
import { Location } from '@angular/common';
import { Template } from 'src/app/models/template.model';
import { ApplicationCreationRequest } from 'src/app/models/application-creation-request.model';
import { ApplicationCreationResponse } from 'src/app/models/application-creation-response.model';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'app-create-app-form',
  templateUrl: './create-app-form.component.html',
  styleUrls: ['./create-app-form.component.scss']
})
export class CreateAppFormComponent implements OnInit, OnDestroy {
  templates$: Observable<Template[]>;
  policyAccepted$: Observable<boolean>;
  selectedTemplateName: string = '';
  user: User;
  isAuthenticated = false;
  isDomainAvailable = true;
  private subscriptions = new Subscription();

  // Form controls defined as separate properties
  templateType = new FormControl('', Validators.required);
  appName = new FormControl('', Validators.required);
  offer = new FormControl('SUBDOMAIN', Validators.required);
  domainName = new FormControl('', [Validators.required, Validators.pattern('([\\da-z-]+)')]);
  acceptTerms = new FormControl(false);

  applicationForm: FormGroup;

  constructor(
    private store: Store,
    private router: Router,
    private applicationService: ApplicationService,
    private seoService: SeoService,
    private route: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.seoService.setSeoTags({
      titleKey: "seo.app_creation.title",
      descriptionKey: "seo.app_creation.description",
      imageKey: "seo.app_creation.image",
      noindex: true
    });

    this.templates$ = this.store.select(selectTemplates);
    this.store.dispatch(loadUserById());

    this.store.select(selectIsAuth).subscribe(isAuthenticated => {
      this.isAuthenticated = isAuthenticated;
    });

    this.initializeForm();

    this.route.queryParams.subscribe(params => {
      this.templateType.setValue(params['name'] || '');
      this.preselectTemplate();
      this.location.replaceState(this.router.url.split('?')[0]);
    });

    this.policyAccepted$ = this.store.select(selectHasAcceptedOurConditionsAndPrivacyPolicy);
    this.policyAccepted$.subscribe((hasPolicyAccepted) => {
      this.acceptTerms.setValue(hasPolicyAccepted);
      if (!hasPolicyAccepted) {
        this.acceptTerms.setValidators(Validators.requiredTrue);
      }
    });
  }

  initializeForm(): void {
    this.applicationForm = new FormGroup({
      templateType: this.templateType,
      appName: this.appName,
      offer: this.offer,
      domainName: this.domainName,
      acceptTerms: this.acceptTerms
    });
  }

  // Simplify control access in submit, error handling, etc.
  submit(): void {
    if (!this.applicationForm.valid) return;

    const request: ApplicationCreationRequest = {
      appName: this.appName.value,
      idTemplate: this.templateType.value,
      subDomainName: this.domainName.value,
      domainType: this.offer.value
    };

    this.applicationService.createApplication(request).subscribe({
      next: (uploadResult) => this.handleSuccess(uploadResult),
      error: (err) => this.handleError(err)
    });
  }
  private preselectTemplate(): void {
    this.templates$.subscribe(templates => {
      const matchingTemplate = templates.find(template => template.name === this.applicationForm.controls['templateType'].value);
      this.applicationForm.controls['templateType'].setValue(matchingTemplate ? matchingTemplate.id : '');
      this.selectedTemplateName = matchingTemplate ? matchingTemplate.name : '';
    });
  }
  private handleSuccess(uploadResult: ApplicationCreationResponse): void {
    this.store.dispatch(setTemplateType({ templateType: null }));
    this.store.dispatch(clearCreationOrder());
    const redirectUrl = uploadResult.redirectToWaitingRoom
      ? ['/app-loading', uploadResult.application.id]
      : ['/dashboard', 'applications', uploadResult.application.id, 'overview'];
    this.router.navigate(redirectUrl);
  }

  private handleError(error: any): void {
    if (error.error && error.error.error === "toastr.info_domain_name_unavailable") {
      this.isDomainAvailable = false;
    }
  }
  
  redirectToTemplate(): void {
    if (this.applicationForm.controls['templateType'].value) {
      this.router.navigate(['/store', this.applicationForm.controls['templateType'].value]);
    }
  }
    
  updateTemplateName(templateId: string): void {
    this.templates$.subscribe(templates => {
      const selectedTemplate = templates.find(template => template.id === templateId);
      this.selectedTemplateName = selectedTemplate ? selectedTemplate.name : '';
    });
  }

  onTemplateTypeChange(event: any): void {
    const templateId = event.target.value;
    this.updateTemplateName(templateId);
  }
  
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
