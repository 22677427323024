<!--<nav-->
<!--  id="navbar-main"-->
<!--  class="navbar navbar-light navbar-horizontal navbar-main navbar-expand-lg"-->
<!--&gt;-->
<nav class="navbar navbar-horizontal navbar-expand-lg navbar-main sticky-top shadow" style="flex-flow:wrap;">
  <!-- Navbar content -->
  <!--<nav-->
  <!--  class="navbar navbar-light navbar-horizontal navbar-main navbar-expand-lg"-->
  <!--  id="navbar-main"-->
  <!--&gt;-->
  <div class="row w-100 mx-0">
    <div class="container d-flex align-items-center justify-content-between" style="min-height: 7vh;">
      <!-- Hamburger -->
      <button
      class="navbar-toggler"
      type="button"
      id="navbarToggler"
      #navbarToggler
      (click)="isCollapsed = !isCollapsed"
      aria-expanded="false"
      aria-controls="navbarCollapse"
    >
      <div class="sidenav-toggler-inner">
        <i class="sidenav-toggler-line bg-primary"></i>
        <i class="sidenav-toggler-line bg-primary"></i>
        <i class="sidenav-toggler-line bg-primary"></i>
      </div>
    </button>

      <div class="navbar-toggler" data-toggle="collapse">
        <a class="nav-link" (click)="redirectToResourcePage('')">
          <img src="../../../../assets/img/logo/logo_color.png" alt="Pirog Logo" style="height:30px" />
        </a>
      </div>

      <div class="navbar-toggler" data-toggle="collapse">
        <li class="nav-item dropdown my-3" dropdown placement="bottom-right" *ngIf="(isAuth$ | async) === false">
          <a class="nav-link nav-icon text-center px-0" role="button" dropdownToggle
            (click)="isAppSearchActivated = false">
            <i class="fas fa-user-circle text-primary"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-lg bg-default dropdown-menu-right p-4" *dropdownMenu>
            <app-auth-menu (onClick)="clearDropNav()"></app-auth-menu>  <!-- Appel de la méthode pour fermer le menu -->
          </div>
        </li>
      </div>

      <div
      class="collapse navbar-collapse"
      id="navbarCollapse"
      #navbarCollapse
      [class.show]="!isCollapsed"
    >
        <ul class="navbar-nav mr-auto align-items-lg-center ml-lg-auto text-uppercase text-black text-center">
          <li class="nav-item">
            <a class="nav-link" (click)="redirectToResourcePage('')">
              <img src="../../../../assets/img/logo/logo_color.png" alt="Pirog Logo" style="height:30px" />
            </a>
          </li>
          <!-- DESKTOP LINKS -->
          <!-- PRODUCTS link -->
          <li class="nav-item display-desktop" dropdown placement="bottom-right">
            <a class="nav-link" role="button" (click)="setDropNav('PRODUCTS')" #productsButton>
              <span class="nav-link-inner--text">
                <strong>{{ 'navbar.products' | translate }}<small class="ml-1">▼</small>
                </strong>
              </span>
            </a>
          </li>
          <li class="nav-item display-desktop">
            <a class="nav-link" role="button" (click)="setDropNav('RESOURCES')" #resourcesButton>
              <span class="nav-link-inner--text"><strong>{{ 'navbar.resources' | translate }}<small
                    class="ml-1">▼</small></strong></span>
            </a>
          </li>
          <!-- MOBILE LINKS -->
          <li class="nav-item display-mobile col-12" dropdown placement="bottom-right">
            <app-products-list (closeMenu)="clearDropNav()"></app-products-list>
          </li>

          <li class="nav-item display-mobile">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/explore']" (click)="isCollapsed = true">
              <span class="nav-link-inner--text"><strong>{{ 'navbar.explore' | translate }}</strong></span>
            </a>
          </li>

          <li class="nav-item display-mobile">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/doc']" (click)="isCollapsed = true">
              <span class="nav-link-inner--text"><strong>{{ 'navbar.doc' | translate }}</strong></span>
            </a>
          </li>

          <!-- DESKTOP & MOBILE LINKS -->
          <li class="nav-item ">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/about']" (click)="isCollapsed = true">
              <span class="nav-link-inner--text"><strong>{{ 'navbar.about' | translate }}</strong></span>
            </a>
          </li>
          <!-- Pricing -->
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/pricing']" (click)="isCollapsed = true">
              <span class="nav-link-inner--text"><strong>{{ 'navbar.pricing' | translate }}</strong></span>
            </a>
          </li>
          <!-- Contact -->
          <li class="nav-item">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/contact']" (click)="isCollapsed = true">
              <span class="nav-link-inner--text"><strong>{{ 'navbar.contact' | translate }}</strong></span>
            </a>
          </li>
        </ul>
        <hr class="d-lg-none" />
        <ul class="navbar-nav align-items-lg-center ml-lg-auto">

          <!-- <li class="nav-item d-none d-lg-block mx-4">
            <button *ngIf="!router.url.includes('order') && templateType !== null && templateType !== 'null'" class="btn btn-icon btn-light" type="button" (click)="goToCreateAppForm()">
              <span class="btn-inner--icon"> <i class="ni ni-cart"> </i> </span>
              <span class="badge badge-md badge-circle badge-floating badge-success">1</span>
            </button>
          </li>-->

          <!-- DESKTOP LINKS -->

          <!-- 9 carrés -->
          <li class="nav-item dropdown display-desktop" dropdown placement="bottom-right">
            <a class="nav-link nav-icon dropdown-toggle mr-2" role="button" (click)="isAppSearchActivated = false"
              dropdownToggle>
              <i class="fas fa-th"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-lg  bg-default dropdown-menu-right p-4" *dropdownMenu>
              <div class="row ">
                <a *ngFor="let template of templates$ | async" class="col-6"
                  (click)="redirectToProductPage(template.name)">
                  <div class="">
                    <div class="d-flex flex-row mb-3">
                      <div (click)="redirectToResourcePage('')">
                        <img src="assets/templates/{{template.name}}/icone_color-min.webp" class="img-template "
                          alt="{{ 'seo.landing.img_alt.carousel_small_1' | translate }}" />
                      </div>
                      <div class="ml-2 mt-auto mb-auto">
                        <small>{{template.name}}</small>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>

            <!-- <hr class="m-0"/>
              <div (click)="$event.stopPropagation()">
                <h4 class="mt-3">{{ 'navbar.search_community' | translate }}</h4>
                <h5 class="font-weight-light mt--1 mb-2">{{ 'navbar.search_community_description' | translate }}</h5>
                <search-bar
                  placeholder="{{ 'navbar.search_community_placeholder' | translate }}"
                  (search)="handleTemplateSearch($event)"
                ></search-bar>
              </div>
            </div> -->
          </li>
          <li class="nav-item display-desktop">
            <search-bar *ngIf="isAppSearchActivated"
              placeholder="{{ 'navbar.search_community_placeholder' | translate }}" (search)="handleAppSearch($event)">
            </search-bar>
          </li>

          <!-- <li *ngIf="!isAppSearchActivated" class="nav-item dropdown display-desktop" dropdown placement="bottom-right"
            (click)="isAppSearchActivated = true">
            <a class="nav-link nav-icon" role="button">
              <i class="fas fa-xl fa-search text-center"></i>
            </a>
          </li>
          -->
          <div class="vertical-hr ml-1 mr-3 display-desktop"></div>


          <!-- DESKTOP & MOBILE LINKS -->
          <li class="nav-item dropdown my-3" dropdown placement="bottom-right" *ngIf="(isAuth$ | async) === false">
            <a class="nav-link nav-icon text-center" role="button" dropdownToggle
              (click)="isAppSearchActivated = false">
              <i class="fas fa-user-circle"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-lg  bg-default dropdown-menu-right p-4" *dropdownMenu>
              <app-auth-menu (onClick)="clearDropNav()"></app-auth-menu>
            </div>
          </li>
          <li class="nav-item d-block my-1 text-center" *ngIf="(isAuth$ | async) === true">
            <a class="btn btn-sm btn-secondary" [routerLink]="['/dashboard/applications']">
              {{ 'navbar.myapps' | translate }}
            </a>
          </li>
          <li class="nav-item dropdown my-1 text-center" dropdown placement="bottom-right"
            *ngIf="(isAuth$ | async) === true">
            <a class="nav-link pr-0 dropdown-toggle" role="button" dropdownToggle>
              <div class="media justify-content-center align-items-center">
                <span>
                  <app-profile-image avatarSize="sm" [showName]="true"></app-profile-image>
                </span>
              </div>
            </a>
            <div class="dropdown-menu dropdown-menu-arrow dropdown-menu-right" *dropdownMenu>
              <div class="dropdown-header noti-title">
                <h6 class="text-overflow m-0">{{'navbaradmin.welcome'|translate}}</h6>
              </div>
              <a routerLinkActive="active" [routerLink]="['/dashboard/account']" class="dropdown-item">
                <i class="ni ni-single-02"></i> <span>{{'navbaradmin.account'|translate}}</span>
              </a>
              <!-- <a routerLinkActive="active" href="{{stripeUrl}}" target="_blank" class="dropdown-item">
                <i class="ni ni-settings-gear-65"></i> <span>{{'navbaradmin.billing'|translate}}</span>
              </a> -->

              <div class="dropdown-divider"></div>
              <a (click)="logout()" class="dropdown-item">
                <i class="ni ni-user-run"></i> <span>{{'navbar.logout'|translate}}</span>
              </a>
            </div>
          </li>

          <!-- NON AUTH -->
          <li class="nav-item text-center" *ngIf="(isAuth$ | async) === false">
            <a class="btn btn-sm btn-secondary ml-3 mr-3" routerLinkActive="active" [routerLink]="['/register']"
              (click)="isCollapsed = true">
              <span class="nav-link-inner--text">{{'navbar.start_free'|translate}}</span>
            </a>
          </li>
          <li *ngIf="(isAuth$ | async) === false" class="nav-item dropdown" dropdown placement="bottom-right">
            <a class="nav-link pr-0 dropdown-toggle" role="button" dropdownToggle>
              <div class="media align-items-center justify-content-center">
                <h2 class="mt-1 lang-dropdown">{{ currentLang | uppercase }}</h2>
              </div>
            </a>
            <div class="dropdown-menu dropdown-menu-arrow dropdown-menu-right" style="min-width: 5rem;" *dropdownMenu>
              <a class="dropdown-item text-center rounded" (click)="switchLanguage('en')">
                <span>EN</span>
              </a>
              <a class="dropdown-item text-center rounded" (click)="switchLanguage('fr')">
                <span>FR</span>
              </a>
            </div>
          </li>

        </ul>
      </div>
    </div>
  </div>

  <div class="w-100">
    <div #products *ngIf="displayedDropNav === 'PRODUCTS'"
      class="navbar-horizontal navbar-expand-lg drop-nav position-absolute">
      <div class="container p-4">
        <app-products-list (closeMenu)="clearDropNav()"></app-products-list>
      </div>
    </div>
  </div>

  <div class="w-100">
  <div #resources *ngIf="displayedDropNav === 'RESOURCES'"
    class="navbar-horizontal navbar-expand-lg drop-nav position-absolute">
    <div class="container p-4">
      <app-resources-list (closeMenu)="clearDropNav()"></app-resources-list>
    </div>
  </div>
  </div>
</nav>
