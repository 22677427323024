<div class="main-content">
  <div class="header bg-white py-7 py-lg-8 pt-lg-9">
    <div class="container">
      <div class="header-body text-center mb-7">
        <div class="row justify-content-center">
          <div class="col-xl-5 col-lg-6 col-md-8 px-5">
            <h1 *ngIf="(verifyAccountSuccess$ | async) === true">{{ 'toastr.success_account_validated' | translate }}</h1>
            <h1 *ngIf="(verifyAccountSuccess$ | async) === false">{{ 'toastr.error_account_validation_failed' | translate }}</h1>

            <!-- <p class="text-lead" *ngIf="(verifyAccountSuccess$ | async) === true">
              {{ 'account_validation.p_0' | translate }}
            </p>
            <p class="text-lead" *ngIf="(verifyAccountSuccess$ | async) === false">
              {{ (error$ | async) | translate }}
            </p> -->
          </div>
        </div>
      </div>
    </div>

    <div class="separator separator-bottom separator-skew zindex-100">
      <svg
        x="0"
        y="0"
        viewBox="0 0 2560 100"
        preserveAspectRatio="none"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
      </svg>
    </div>
  </div>

  <div class="container mt--8 pb-5">
    <div class="row justify-content-center">
      <div class="col-lg-5 col-md-7">
        <div class="card bg-light border-0 mb-0">

          <div class="card-body text-center px-lg-5 py-lg-5">
            <div *ngIf="(verifyAccountSuccess$ | async) === true">
              <app-icon-success-animated class="text-center"></app-icon-success-animated>
              <div class="mt-3">
                <!--  -->
                <button class="btn btn-icon btn-primary text-white" type="button" *ngIf="(urlOrderFromStep$ | async) !== null && !router.url.includes('order')"  [routerLink]="['/order']">
                  {{ 'account_validation.button_text' | translate }}
                  <span class="badge badge-md badge-circle badge-floating badge-success">1</span>
                </button>
              </div>
            </div>
            <div *ngIf="(verifyAccountSuccess$ | async) === false">
              <app-icon-error-animated></app-icon-error-animated>
              <div>
                <h3>{{ 'account_validation.h3_0' | translate }}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
