import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectIsAuth } from '../store/auth/auth.selectors';
import { PlatformService } from '../services/plateform.service';

@Injectable({
    providedIn: 'root',
})
export class NoAuthGuard  {
    constructor(private store: Store, private router: Router, private platformService: PlatformService) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.getAuth$();
    }

    canLoad(
        route: Route,
        segments: UrlSegment[]
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.getAuth$();
    }

    getAuth$(): Observable<boolean> {
        return this.store.select(selectIsAuth).pipe(
            map((isAuth: any) => {
                if (isAuth) {
                    this.platformService.log('NOT AUTHENTICATED 4 !!');
                    this.router.navigate(['/']);
                    return false;
                }
                return true;
            })
        );
    }
}
